/**  =====================
      Authentication css start
==========================  **/

.sideMenu{
	position: fixed;
	display:flex; justify-content:center;  
	height:100vh; background:rgb(230, 226, 224);
	overflow: hidden; 
}
.logoImage{height:100%;}
.authContainer{ background: rgba(247, 247, 247, 0.2) }
.formContainer{ min-height:100vh; margin-bottom:-40px; display:flex; justify-content:center;}
.formContainer > div{ margin-bottom:5px;}
.logoText{font-size: 1.7em; font-family: Avenir; color:#000;}
.authText{font-family: Avenir; color: rgb(94, 94, 94)}
.loginButton{ 
	background: rgb(130, 31, 93); 
	color: #fff; width:40%; 
	padding: 15px; border-radius: 20px;
}
.loginButton:hover{color: #fff;}
.form-group { text-align: left;}
.form-group > label{padding-left:10px;}
.form-group .form-control{ border-radius: 5px;}
.form-control{ border-radius: 20px;}
.layout-icon { font-size: 2.5em; margin-top:15px; color: rgb(130, 31, 93); }

.copyRightFooter{ height: 40px; margin-top: -40px; padding:5px 0;}

@media only screen and (max-width: 767px) {
	.sideMenu{position: absolute; height:10vh; align-items:center;}
	.authContainer{margin-top:10vh;}
	.logoImage{max-width:60px;}
	.formContainer{ height: 90vh;}
	.copyRightFooter{ display: none; }
}

/**====== Authentication css end ======**/